import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Treatments from "../components/Treatments"

const TreatmentsPage = () => {

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query TreatmentsList {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(tratamentos)/"  }}) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
              highlightTitle
              title
              img {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
            }
          }
        }
      }
    }
  `)

  const data = allMarkdownRemark.edges

  return (
    <Layout title="Tratamentos">
      <SEO title="Home" />
      <Treatments posts={data}/>
    </Layout>
  )
}

export default TreatmentsPage